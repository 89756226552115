<template>
    <v-img :src="imageData" :height="height" :width="width" :max-height="maxHeight" :max-width="maxWidth" :min-height="minHeight" :min-width="minWidth" :contain="contain" :aspect-ratio="aspectRatio" :content-class="contentClass"></v-img>
</template>

<script>
export default {
    /**
     * the `imageId` prop is required to load the 'logo' image for a realm
     * the `etag` prop is optional, but can be used to reload an image (e.g. after a new image was uploaded, change the etag to Date.now() in parent component)
     * the other props are passed to v-img
     *
     * NOTE: Vue automatically translates hyphenated names such as "max-width" to camelCase "maxWidth" so when using the component you write :max-width="192" but here we receive it as "maxWidth".
     */
    props: ['imageId', 'etag', 'height', 'width', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth', 'contain', 'aspectRatio', 'contentClass'],
    // props: {
    //     imageId: {
    //         type: String,
    //         default: null,
    //     },
    //     height: {
    //         type: Number,
    //     },
    //     width:
    // },

    data: () => ({
        content: null,
        contentType: null, // 'image/png'
    }),

    computed: {
        imageData() {
            if (this.contentType && this.content) {
                return `data:${this.contentType};base64,${this.content}`;
            }
            return '';
        },
    },

    watch: {
        etag() {
            this.loadImage();
        },
    },

    methods: {
        async loadImage() {
            try {
                this.$store.commit('loading', { loadRealmLogoImage: true });
                const response = await this.$client.account(this.$route.params.accountId).image.get({ id: this.imageId, withContent: true });
                if (response) {
                    this.contentType = response.content_type;
                    this.content = response.content_base64;
                }
            } catch (err) {
                console.log('realmlogoimage.vue loadImage failed', err);
            } finally {
                this.$store.commit('loading', { loadRealmLogoImage: false });
            }
        },
    },

    mounted() {
        this.loadImage();
    },

};
</script>
