<template>
    <v-row no-gutters>
        <v-col cols="12">
            <BrandBar :accountId="$route.params.accountId" :brandId="$route.params.brandId" :brand="brand" class="mb-6"></BrandBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId } }">Images</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ image.label }}</h1>
                <p class="text-caption text-center">Edit image</p>
                </v-col>
            </v-row> -->

            <v-row justify="center" class="py-5 px-10" v-if="image">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Brand Image
                        </v-toolbar-title>
                        <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn icon color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                <!-- Publish -->
                            </v-btn>
                        </template>
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ image.label }}
                        <v-btn icon color="green" @click="editImageLabel">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Intent</p>
                    <p class="mb-0 pb-0">
                        {{ image.intent }}
                        <!-- <v-btn icon color="green" @click="editImageIntent">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->
                    </p>

                    <p class="text-overline mb-0 mt-8">Mode</p>
                    <p class="mb-0 pb-0">
                        {{ image.mode }}
                        <!-- <v-btn icon color="green" @click="editImageMode">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->
                    </p>

                    <p class="text-overline mb-0 mt-8">Image file</p>
                    <p class="mb-0 pb-0">
                        <v-btn class="blue--text" @click="dialogUploadImage = true" text icon>
                            <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>

                    <!-- show preview only if image has a file_id -->
                    <v-row no-gutters align="center" style="width: 300px;" v-if="image.file_id">
                        <!-- TODO: just one file ??? that's a problem, how do we do variants?  different sizes? this needs to be a table or expansion list with variants -->
                        <ImageBox :imageId="image.id" :etag="image.etag" :width="200" contain/>
                        <!-- <v-btn class="blue--text no-print" @click="dialogEditRealmLogoImage = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn> -->
                    </v-row>

                    <!-- <v-dialog v-model="dialogEditImage" max-width="600">
                        <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 pa-0 pb-3">Edit realm logo image</v-card-text>
                            <v-row justify="start" class="px-5 pt-3" style="width: 300px;">
                                <Image :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                                <v-btn class="blue--text" @click="dialogUploadImage = true" text icon>
                                    <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width size="1x"/>
                                </v-btn>
                                <v-btn class="red--text" @click="dialogDeleteImage = true" text icon v-if="realmLogoImage">
                                    <font-awesome-icon :icon="['fas', 'trash']" fixed-width size="1x"/>
                                </v-btn>
                            </v-row>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn text class="black--text" @click="dialogEditImage = false">Close</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog> -->
                    <v-dialog v-model="dialogUploadImage" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title>Upload image</v-toolbar-title>
                            </v-toolbar>
                            <!-- TODO: change this text according to the image intent (square, rectangle, circular) -->
                            <v-card-text class="mx-0 py-3 px-5">
                                Image will be contained in a 300x300 square, but image does not need to be square.
                                <p class="text-overline">{{ imageModeDisplay }}</p>
                                <p v-if="image.mode === 'light'">
                                    Image should use darker colors to look good in Light Mode.
                                </p>
                                <p v-if="image.mode === 'dark'">
                                    Image should use lighter colors to look good in Dark Mode.
                                </p>
                            </v-card-text>
                            <v-row justify="center" class="mx-5 pt-3">
                                <v-file-input
                                    v-model="editableImage"
                                    show-size
                                    accept="image/png"
                                    :rules="imageRules"
                                    label="Choose a logo image (.png)"
                                    outlined>
                                </v-file-input>
                            </v-row>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="uploadImage" :disabled="!editableImage">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogUploadImage = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <v-dialog v-model="dialogDeleteImage" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Delete realm logo image</v-card-text>
                            <v-row justify="center" class="px-5 pt-3 my-5" style="width: 300px;">
                                <Image :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                            </v-row>
                            <v-row justify="center" class="pb-2">
                                <v-btn elevation="4" class="red white--text" @click="deleteImage">
                                    Delete
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogDeleteImage = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog> -->

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ image.comment }}
                        <v-btn icon color="green" @click="editImageComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Image ID</p>
                    <p class="mb-0 pb-0">
                        {{ image.id }}
                    </p>

                    <!-- TODO: the affected X list should depend on the image type... instead of hard-coding email contacts -->
                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This image is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            <!-- TODO: show the affectedEmailContactList -->
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This image is not currently applied to any email contacts.
                        </span>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, imageId: this.$route.params.imageId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->

                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Image Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editImageLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the image label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The image label informs users about the content of communications they will receive if they subscribe to it.
                            You can change it at any time, but the new meaning should be similar to the old one. It IS shown to users.
                        </p>

                        <v-text-field
                            ref="imageLabelInput"
                            v-model="editableImageLabel"
                            label="Label"
                            :rules="newImageLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditImageLabel" :disabled="!isEditImageLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editImageLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editImageCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the image comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The image comment is a place where you can add more information for your own reference about how you use the image.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableImageComment" label="Image comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditImageComment" :disabled="!isEditImageCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editImageCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import ImageBox from '@/components/ImageBox.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import BrandBar from '@/components/account-dashboard/BrandBar.vue';
// import ImageList from '@/components/account-dashboard/ImageList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // ImageList,
        ImageBox,
        AccessDeniedOverlay,
        BrandBar,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        forbiddenError: false,
        account: null,
        brand: null,
        image: null,

        editImageLabelDialog: false,
        editImageCommentDialog: false,
        editableImageComment: null,

        editableImageLabel: null,

        dialogUploadImage: false,
        dialogEditImage: false,
        dialogDeleteImage: false,
        editableImage: [],
        imageRules: [
            (v) => (v && v.size < 400 * 1024) || 'File size should be less than 400 KB!',
        ],

        isDraft: null, // true if not published yet, or if newer than published version (such as updated image content)
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditImageLabelComplete() {
            return typeof this.editableImageLabel === 'string' && this.editableImageLabel.trim().length > 0;
        },
        isEditImageCommentComplete() {
            return typeof this.editableImageComment === 'string' && this.editableImageComment.trim().length > 0;
        },
        imageModeDisplay() {
            switch (this.image?.mode) {
            case 'light':
                return 'Light Mode';
            case 'dark':
                return 'Dark Mode';
            default:
                return this.image?.mode;
            }
        },
    },
    watch: {
        $route(newValue, oldValue) {
            if (newValue.query.brand_id !== oldValue.query.brand_id) {
                this.loadBrand(newValue.query.brand_id);
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadImage() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadImage: true });
                const response = await this.$client.account(this.$route.params.accountId).image.get({ id: this.$route.params.imageId });
                console.log(`loadImage: response ${JSON.stringify(response)}`);
                if (response) {
                    this.image = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.image.comment ??= '';
                    // this.image.reply_to ??= '';
                    this.$set(this.image, 'etag', Date.now()); // force ImageBox to reload
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load image');
                }
            } catch (err) {
                console.error('failed to load image', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadImage: false });
            }
        },
        async checkIsDraft() {
            try {
                this.$store.commit('loading', { checkIsDraft: true });
                const response = await this.$client.account(this.$route.params.accountId).image.check({ brand_id: this.$route.query.brand_id, image_id: this.$route.params.imageId, item: 'draft' });
                console.log(`checkIsDraft: response ${JSON.stringify(response)}`);
                this.isDraft = response?.isDraft;
            } catch (err) {
                console.error('failed to check image status', err);
                this.isDraft = null;
            } finally {
                this.$store.commit('loading', { checkIsDraft: false });
            }
        },
        async save(imageAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditImage: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).image.edit({ id: this.$route.params.imageId }, imageAttr);
                console.log(`saveEditImage: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = true;
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit image' });
                return false;
            } catch (err) {
                console.error('failed to edit image', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit image' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditImage: false });
            }
        },
        editImageLabel() {
            this.editImageLabelDialog = true;
            this.editableImageLabel = this.image.label;
        },
        editImageComment() {
            this.editImageCommentDialog = true;
            this.editableImageComment = this.image.comment;
        },
        async saveEditImageLabel() {
            const isEdited = await this.save({ label: this.editableImageLabel });
            this.editImageLabelDialog = false;
            if (isEdited) {
                this.$set(this.image, 'label', this.editableImageLabel);
            }
        },
        async saveEditImageComment() {
            const isEdited = await this.save({ comment: this.editableImageComment });
            this.editImageCommentDialog = false;
            if (isEdited) {
                this.$set(this.image, 'comment', this.editableImageComment);
            }
        },
        async uploadImage() {
            /*
            See the note about uploadImage in the 'data' section; because we are
            using a single-file input, the value of uploadImage is set to a single
            File object by the time this method is called.
            */
            // console.log('uploadImage: %o', this.editableImage); // {name: "icon.png", lastModified: 1545595273000, lastModifiedDate: a Date object, size: 8887, ... }
            if (this.editableImage.size > 0) {
                try {
                    this.$store.commit('loading', { uploadImage: true });
                    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
                    const formData = new FormData();
                    formData.append('file', this.editableImage);
                    const result = await this.$client.account(this.$route.params.accountId).image.upload({ id: this.$route.params.imageId }, formData);
                    // await dispatch('loadAccount'); // TODO: reload just the affected account
                    // if (result && result.isEdited) {
                    //     this.realm.etag = Date.now(); // notify realm logo image component to reload the image
                    //     this.$bus.$emit('snackbar', { type: 'success', message: 'Updated image' });
                    // } else {
                    //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update image' });
                    // }
                    console.log(`upload result: ${JSON.stringify(result)}`);
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Updated image' });
                    // reload the image from server for preview
                    this.loadImage();
                    this.isDraft = true;
                } catch (err) {
                    console.log('uploadImage failed', err);
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update image' });
                } finally {
                    this.$store.commit('loading', { uploadImage: false });
                    this.dialogUploadImage = false;
                    this.editableImage = null;
                }
            }
        },
        async publish() {
            try {
                this.error = false;
                this.$store.commit('loading', { publish: true });
                const response = await this.$client.account(this.$route.params.accountId).image.publish({ id: this.$route.params.imageId });
                console.log(`publish: response ${JSON.stringify(response)}`);
                if (response?.isPublished) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish image' });
                }
            } catch (err) {
                console.error('failed to publish image', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish image' });
            } finally {
                this.$store.commit('loading', { publish: false });
            }
        },
        async loadBrand(id) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrand: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.get({ id });
                console.log(`loadBrand: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brand = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand');
                }
            } catch (err) {
                console.error('failed to load brand', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrand: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadImage();
        this.checkIsDraft();
        if (this.$route.query.brand_id) {
            this.loadBrand(this.$route.query.brand_id);
        }
    },
};
</script>
